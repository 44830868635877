export default {
    methods: {
        async loadData(data, callback) {
            var rs = await this.$store.dispatch('getAskListByCategory', data)
            if(rs.total != null) {
                callback && callback({
                    list: rs.list,
                    total: rs.total,
                }, null)
            } else {
                callback && callback(null, rs)
            }
        }
    }
}